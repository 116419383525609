import React, { useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import classes from "../styles/signin.scss";
import "@okta/okta-signin-widget/dist/css/okta-sign-in.min.css";
import EnrollmentForm from "./EnrollmentForm";
import logo from "../images/logo-main.png";
import { useLocation } from "react-router-dom";
import Footer from "./Footer";


export const getEnrollmentType = (pathReceived) => {
    let enrollmentRoute = "";
    if (pathReceived.includes("/phone-enrollment"))
        enrollmentRoute = "phone-enrollment";
    else if (pathReceived.includes("/email-enrollment"))
        enrollmentRoute = "email-enrollment";
    else if (pathReceived.includes("/setup-phone-enrollment"))
        enrollmentRoute = "setup-phone-enrollment";
    else if (pathReceived.includes("/enrolled")) {
        enrollmentRoute = "enrolled";
    }
    else if (pathReceived.includes("/account-setup-complete")) {
        enrollmentRoute = "account-setup-complete";
    }
    else if (pathReceived.includes("/password-reset-complete")) {
        enrollmentRoute = "password-reset-complete";
    }
    return enrollmentRoute;
}


const EnrollmentUI = () => {
    const { authState } = useOktaAuth();
    const location = useLocation();

    useEffect(() => {
    }, [location]);

    if (!authState) return null;

    return (
        <div className="login-wrapper login-wrapper-inner-page" id="login-wrapper">
            <div className="login-container">
                <div className="">
                    <div className="row w-100 m-0">
                        <div className="col-xl-6 p-0 col-lg-12 left-box">
                            <div className="phx-form-login m-auto">

                                <div >
                                    <div className='margin-center-widget'>
                                        <div className="auth-container main-container no-beacon" style={{ classes }} data-se="auth-container" tabndex="-1" id="okta-sign-in">
                                            <div className="okta-sign-in-header auth-header">
                                                <h1><img src={logo} className="auth-org-logo" alt=" logo" aria-label=" logo" /></h1>
                                                <div data-type="beacon-container" className="beacon-container"></div>
                                            </div>
                                            <div className="auth-content">
                                                <div className="auth-content-inner">
                                                    <EnrollmentForm props={getEnrollmentType(window.location.pathname)} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
};

export default EnrollmentUI;
