export const storeFrontURLs = [
  // dev urls
  "https://orderexpresspmoddev.cardinalhealth.com",
  "https://www1pmoddev.cardinalhealth.com",
  "https://specialtyonlinepmoddev.cardinalhealth.com",
  "https://orderexpresspmoddev.cardinalhealth.com/reportcenter",
  "https://reportcenterdev.cardinalhealth.com/reportcenter",
  "https://oeintfc.dev.cardinalhealth.net/Interface_Setup_war/interfaceSetupHomePage.action",
  "https://vantusdev.cardinalhealth.com",

  // QA urls
  "https://orderexpresspmodstg.cardinalhealth.com",
  "https://www1pmodstg.cardinalhealth.com",
  "https://specialtyonlinepmodstg.cardinalhealth.com",
  "https://orderexpresspmodstg.cardinalhealth.com/reportcenter",
  "https://oeintfc.stg.cardinalhealth.net/Interface_Setup_war/interfaceSetupHomePage.action",
  "https://reportcenterstg.cardinalhealth.com/reportcenter",
  "https://vantusqa.cardinalhealth.com",

  // Stage urls
  "https://vantusstg.cardinalhealth.com",

  // Prod urls
  "https://orderexpress.cardinalhealth.com",
  "https://orderexpress.cardinalhealth.com",
  "https://www1.cardinalhealth.com",
  "https://specialtyonline.cardinalhealth.com",
  "https://orderexpress.cardinalhealth.com/reportcenter",
  "https://oeintfc.cardinalhealth.net/Interface_Setup_war/interfaceSetupHomePage.action",
  "https://reportcenter.cardinalhealth.com/reportcenter",
  "https://jarvis.cardinalhealth.com",
];
