/* istanbul ignore file */
import React from 'react';
import { Cookies } from 'react-cookie';
import '../styles/pilot.css';
//import logo from '../images/logo-main.png';
import logo from '../images/logo.webp';
import {envConfig} from '../../src/config';
import { storeFrontURLs } from './storeFrontURLs';

let oeUrl = envConfig.ACUTE_TARGET_URL;

//setmax age of cookie (end of august)
const maxAgeTime = new Date(envConfig.COOKIE_MAXAGE_DATE);
//set deadline for enrollment
const deadline = new Date(envConfig.ENROLL_DEADLINE_DATE);
const today = new Date();
//calculate difference in seconds for max age
const cookieMaxAge = Math.abs(maxAgeTime - today) / 1000;
//set days before pilot deadline to stop users from enrolling too close to deadline
const daysBeforeDeadline = envConfig.DAYS_BEFORE_DEADLINE;
const pilotEnrollCutoff = new Date(today.setDate(today.getDate() + daysBeforeDeadline));
const regexPattern = new RegExp(envConfig.URL_REGEX_PATTERN);
//set cookie & cookie name
const cookies = new Cookies();
const pilotCookie = envConfig.PILOT_COOKIE;

// pastDeadline boolean
let pastDeadline = false;
let allowEnroll = false;

function setCookie() {
    cookies.set(pilotCookie, true,
    {
        path: "/",
        domain: envConfig.COOKIE_DOMAIN,
        maxAge: cookieMaxAge,
    });
};

export default function Pilot() {
    // search URL for action parameter and fromURI
    const searchParams = new URLSearchParams(window.location.search);
    const action = searchParams.get('action');
    const userid = searchParams.get('userid');
    const redirectTo = searchParams.get('fromURI');

    // Function to validate the URL against the whitelist
    function isValidRedirectUrl(url) {
        // Check if the URL is in the approved list
        // return storeFrontURLs.some(approvedUrl => url.startsWith(approvedUrl));
        try {
            const parsedUrl = new URL(url);
            // Check if the URL is in the approved list
            return storeFrontURLs.some(approvedUrl => parsedUrl.origin.startsWith(approvedUrl));
        } catch (e) {
            return false;
        }
    }
    
    // If the redirect To variable is present and the redirect includes the 
    // activation URL, we will set allowEnroll to true to skip
    // over the deadline 
    if(redirectTo && regexPattern.test(redirectTo) || action === 'servicecenter'){
        console.log('pattern match!');
        allowEnroll = true;
    }
    
    if(pilotEnrollCutoff  > deadline && allowEnroll === false){
        pastDeadline = true;
    }
    else if(action === "optin" && redirectTo){
        setCookie();
        // Validate the URL
        if (isValidRedirectUrl(redirectTo)) { 
            // Additional security check: Ensure the URL is not an external entity
            if (!redirectTo.includes("file:") && !redirectTo.includes("ftp:") && !redirectTo.includes("http:")) {
                // If valid, perform the redirection
                // window.location.replace(encodeURI(redirectTo));
            } else {
                // If not valid, handle the error
                console.error('Invalid redirect URL: External entities are not allowed');
            }
        } else {
            // If not valid, handle the error
            console.error('Invalid redirect URL');
        }
    }
    
    else if(action === "optin" || action === "servicecenter"){
        setCookie();
    }
    
    return (
        <div className="pilotContainer">
        <div id="pilotDisplayMsg">
            
        <div id="pilotImgContainer"><img src={logo} className="pilot-auth-org-logo" alt=" logo" aria-label=" logo" /></div>
        <hr />
          { 
          // If allow enroll is true, then the user sees nothing and is redirected to the From URI.
          // else, if the user is past the deadline, they are displayed a deadline has passed message
          // else, if there is an action supplied in the request, and there is a cookie in the browser,
          // the user will see a confirmation of their enrollment
          // else, there was no action in the request
            allowEnroll && action != 'servicecenter'
            ? <p>Redirecting...</p>
            : pastDeadline 
                ?<><h2>The enrollment period has ended.</h2><p>Thank you for your interest in the Order Express pilot program. The last day to enroll was {envConfig.ENROLL_DEADLINE_DATE}</p></>
                : action 
                ?
                cookies.get(pilotCookie)
                    ?<><h2>You're in!</h2><p>Get ready to experience our new login and authentication experience <b>beginning {envConfig.PILOT_LAUNCH_DAY_DATE}</b> See you then!</p><a href={oeUrl} target="_blank" rel="noreferrer" className="button">
                    <button className="pilotButton">Back to Order Express</button>
                  </a></>
                    :<p>Invalid action</p>
                :<p>No action supplied in the request.</p>
          }
          </div>
        </div>
      );
}