import { envConfig } from '../config';
const REDIRECT_URI = `${window.location.origin}/login/callback`;
import { signInHelp } from './helpers/functions';
import oktaSignInConfig from './helpers/oktaConfig.json';



// Replace placeholders with actual values from envConfig
Object.keys(oktaSignInConfig.i18n.en).forEach(key => {
    if (oktaSignInConfig.i18n.en[key] === "PLACE_HOLDER") {
        oktaSignInConfig.i18n.en[key] = envConfig[key];
    }
});

oktaSignInConfig.baseUrl = envConfig.OKTA_BASE_URL;
oktaSignInConfig.clientId = envConfig.OKTA_CLIENT_ID;
oktaSignInConfig.redirectUri = REDIRECT_URI;
oktaSignInConfig.authParams.issuer = envConfig.OKTA_ISSUER;

// Handle function calls
oktaSignInConfig.helpLinks.help = signInHelp();

const oktaSignInConfig1_1 = oktaSignInConfig;

export { oktaSignInConfig1_1 };