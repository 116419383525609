import React from "react";
import InfoIcon from "../images/info-icon.svg";
import CloseIcon from "../images/close-icon.svg";
import { envConfig } from "../config";

const Modal = ({ type }) => {
   const brandName = sessionStorage.getItem("whereItCameFrom")
   const brandNameText = brandName == "vantus" ? "Vantus™ HQ" : "Order Express"
  function closeModal() {
    const customModal = document.querySelector(".custom-modal");
    const backgroundOverlay = document.querySelector(".background-overlay");
    if (customModal && backgroundOverlay) {
      customModal.style.display = "none";
      backgroundOverlay.style.display = "none";
    }
  }

  function navigateToSSO() {
    if (type === "sso-modal")
      window.location.href = envConfig.SSO_URL;
  }
  return (
    <div className={`custom-modal ${type}`}>
      <div className="modal-content">
        <div className="modal-header">
          <div className="header-title">Sign in with SSO</div>
          <button className="icon-button" onClick={() => closeModal()}>
            <img src={CloseIcon} alt="close button" />
          </button>
        </div>
        <div className="modal-body">
          {`Single sign-on (SSO) uses your workplace sign-in credentials to access
          ${brandNameText}. Do you want to continue?`}
          <br />
          <br />
          {`Go back to sign in using your ${brandNameText} user ID and password.`}
          <div className="inline-notification">
            <img src={InfoIcon} alt="info icon" />
            <div className="label-text">Info.</div>
            <div class="content">
              Contact your Cardinal Health representative to determine if you
              have SSO access.
            </div>
          </div>
        </div>
        <div className="modal-footer">
          <button className="btn button-secondary" onClick={() => closeModal()}>
            Go back
          </button>
          <button
            className="btn button-primary"
            onClick={() => navigateToSSO()}
          >
            Continue with SSO
          </button>
        </div>
      </div>
    </div>
  );
};

export default Modal;
