import React from 'react';
import { Route, Switch, useHistory } from 'react-router-dom';
import { Security,LoginCallback } from '@okta/okta-react';
import { OktaAuth, toRelativeUrl } from '@okta/okta-auth-js';
import Home from './Home';
import Signin from './signin';
import Pilot from './json/pilot';
import Custom from './CustomUIs/Custom';
import EnrollmentUI from './CustomUIs/EnrollmentUI';
import { oktaAuthConfig, oktaSignInConfig } from './OktaConfig';
import {oktaSignInConfig1_1} from './Utility/OktaConfiguration';
import Signout from './json/signout';
const oktaAuth = new OktaAuth(oktaAuthConfig);

const AppWithRouterAccess = () => {
const history = useHistory();

const customAuthHandler = () => {
      history.push('/signin')
  };

  const restoreOriginalUri = async (_oktaAuth, originalUri) => {
    history.replace(toRelativeUrl(originalUri || '/', window.location.origin));
    };

  return (
    <Security
      oktaAuth={oktaAuth}
      onAuthRequired={customAuthHandler}
      restoreOriginalUri={restoreOriginalUri}
    >
      <Switch>
        <Route path='/' exact={true} component={Home} />
        <Route path='/redirect*' component={Home} />
        <Route path='/signin' render={() => <Signin config={oktaSignInConfig} oktaConfig1_1 ={oktaSignInConfig1_1}/>} />
        <Route path='/signin/callback' component={LoginCallback} />
        <Route path='/pilot*' component={Pilot} />
        <Route path='/reactivate' component={Custom} />
        <Route path='/forgot-username' component={Custom} />
        <Route path='/recovery/emailed' component={Custom} />
        <Route path='/unlock/emailed' component={Custom} />
        <Route path='/username/emailed' component={Custom} />
        <Route path='/signout' component={Signout} />
        <Route path='/phone-enrollment' component={EnrollmentUI} />
        <Route path='/email-enrollment' component={EnrollmentUI} />
        <Route path='/textauth' component={EnrollmentUI} />
        <Route path='/voiceauth' component={EnrollmentUI} />
        <Route path='/setup-phone-enrollment' component={EnrollmentUI} />
        <Route path='/enrolled' component={EnrollmentUI} />
        <Route path='/account-setup-complete' component={EnrollmentUI} />
        <Route path='/password-reset-complete' component={EnrollmentUI} />
        <Route path='/success-text' component={EnrollmentUI} />
        <Route path='/success-voice' component={EnrollmentUI} />
        <Route path='/generic-error' component={Custom} />
        <Route path='/success-email' component={EnrollmentUI} />
      </Switch>
    </Security>
  );
};

export default AppWithRouterAccess;
