import React from "react";
const Footer  = () => {
    return(
        <footer id="footer-container-div2" className="footer-login footer-container-relative">
        <div>
        <div className="d-xl-block d-none">
            <hr className="horizontal-line" />
        </div>
        <div className="d-flex flex-wrap justify-content-between align-items-center  pt-3 pb-3 px-3 py-3">
            <div id="custom-footer">
                <a className="legal-privacy" target="_blank" href="https://www.cardinalhealth.com/us/en/Legal">Terms & conditions</a>
                &nbsp;|&nbsp;
                <a className="legal-privacy" target="_blank" href="http://cardinalhealth.com/us/en/PrivacyPolicy/PrivacyPolicy">Global privacy policy</a>
                &nbsp;|&nbsp;
                <a className="legal-privacy" href="https://www.cardinalhealth.com/en/support/cookie-policy.html">Cookie policy</a>
            </div>
            <div className="copyright">
                 <p>&copy; {new Date().getFullYear()} Cardinal Health. All rights reserved.</p>
            </div>
        </div>
        </div>
        </footer>
    )
}

export default Footer;