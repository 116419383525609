import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import '@okta/okta-auth-js/polyfill';
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

/*
if (typeof (window.AbortController) == 'undefined') {
  import('core-js/es/array');
  import('unfetch/polyfill');
  import('abortcontroller-polyfill');
}*/

ReactDOM.render(    
  <React.StrictMode>
    <div id={"cardinal-header"}>
    <App />
    </div>
  </React.StrictMode>,
  document.getElementById('root')
);
