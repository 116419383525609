import React, { useState, useEffect } from "react";
import { useOktaAuth } from "@okta/okta-react";
import { Redirect,useLocation , useHistory} from "react-router-dom";
import {
  coexistenceRequest,
  resetPasswordConfirmationEmail,
  setPasswordConfirmationEmail,
  incrementUIBypassCounter,
  getFactorsEnrolled,
  getSessionsResponse} from "./resource/APIServices";
import { envConfig, resetPwd, setPwd  } from "./config";
import { useCookies } from 'react-cookie';
import Signout from "./json/signout";
import { featureflagService } from "./resource/APIServices";
//var createBrowserHistory = require("history").createBrowserHistory;
//commit
const DOMAIN = process.env.DOMAIN || "cardinalhealth.com";
let emailEnrolled = false;
let smsEnrolled = false;
let callEnrolled = false;
let userEnrollmentException = false;
let skippedEnrollment = false;



export const findTargetUrl = (userGroups, incomingTargetUri) => {
  // first check if incomingTargetUri is matching with one the userGroups
let userGrpTargetUrlMap = new Map();
userGrpTargetUrlMap.set(envConfig.ACUTE_GRP, [envConfig.ACUTE_TARGET_URL, envConfig.ACUTE_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.RI_GRP, [envConfig.RI_TARGET_URL, envConfig.RI_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.SPOL_GRP, [envConfig.SPOL_TARGET_URL, envConfig.SPOL_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.RC_GRP, [envConfig.RC_TARGET_URL, envConfig.RC_ADDT_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.WEB_ORD_GRP, [envConfig.ACUTE_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.REPORT_GRP, [envConfig.REPORT_TARGET_URL, envConfig.REPORT_CENTER_URL])
userGrpTargetUrlMap.set(envConfig.INTFCADM_GRP, [envConfig.INTFCADM_TARGET_URL])
userGrpTargetUrlMap.set(envConfig.NEW_RI_CORE_COMMERCE_GRP, [envConfig.NEW_RI_CORE_COMMERCE_TARGET_URL, envConfig.NEW_ADDT_RI_CORE_COMMERCE_URL])


let hasMatchedWithGroup = false;
let hasPrimaryStorefrontGroup = false;
let hasSupplementAppGroup = false;
let supplementAppGroup = ''
let primaryStorefrontGroup = '';
userGroups.forEach(eachGrp => {
 if(!hasMatchedWithGroup && incomingTargetUri && [... userGrpTargetUrlMap.keys()].includes(eachGrp)){
      hasMatchedWithGroup = userGrpTargetUrlMap.get(eachGrp).some(targetUrl => incomingTargetUri.includes(targetUrl)); 
      console.log('hasMatchedWithGroup for ' + eachGrp + ' ' + hasMatchedWithGroup)
  }
  if([envConfig.ACUTE_GRP, envConfig.RI_GRP, envConfig.RC_GRP, envConfig.SPOL_GRP,envConfig.NEW_RI_CORE_COMMERCE_GRP].includes(eachGrp)){
    hasPrimaryStorefrontGroup = true;
    primaryStorefrontGroup = eachGrp;
  }
  if([envConfig.WEB_ORD_GRP, envConfig.REPORT_GRP, envConfig.INTFCADM_GRP].includes(eachGrp)){
    hasSupplementAppGroup = true;
    supplementAppGroup = eachGrp;
  }
});
console.log("HasMatched? " + hasMatchedWithGroup + " hasPrimarySF" + hasPrimaryStorefrontGroup + " " + primaryStorefrontGroup + "hasSupplement " + hasSupplementAppGroup + " " + supplementAppGroup)
if(hasMatchedWithGroup){
  return incomingTargetUri;
} else if (!hasMatchedWithGroup && hasPrimaryStorefrontGroup) {
  return userGrpTargetUrlMap.get(primaryStorefrontGroup)[0];
} else if(!hasMatchedWithGroup && hasSupplementAppGroup) {
  return userGrpTargetUrlMap.get(supplementAppGroup)[0];
} else if (!hasMatchedWithGroup && !hasPrimaryStorefrontGroup && !hasSupplementAppGroup){
  return "Unavailable";
}
}


const Home = () => {
 // const history = createBrowserHistory({forceRefresh:true})
 const history = useHistory();
  const location = useLocation();
  const [userInfo, setUserInfo] = useState(null);
  const windowHrefUrl = decodeURIComponent(window.location.href);
  const { oktaAuth, authState } = useOktaAuth();
  
  
  const [coexistence, setCoexistence] = useState(null);
  const [cookies, setCookie] = useCookies(['SMSESSION']);
  const [releaseFeatureFlag, setReleaseFeatureFlag] = useState(null);
  /* eslint-disable */
  useEffect(() => {
    let whereItcameFrom_sessionStorage = sessionStorage.getItem("whereItCameFrom");
    if (whereItcameFrom_sessionStorage === "vantus") {
        document.title = "Vantus\u2122 HQ";
    }
  },[]);

  useEffect(() => {
      const fetchReleaseFlag = async () => {
        try {
          const response = await featureflagService();
          const releaseFlag = response?.release_management?.releases?.PRV_1_1;
           const flag =releaseFlag ?? false;
           setReleaseFeatureFlag(flag);
        } catch (error) {
          console.error('Failed to fetch feature flag:', error);
          setReleaseFeatureFlag(false);
        }
       };
    fetchReleaseFlag();
  }, []);
  
  useEffect(async () => {
    if(releaseFeatureFlag != null){
    if (!authState || !authState.isAuthenticated) {
      setUserInfo(null);
      setCoexistence(null);
    } else {
      let getSessionsResponseTest = await getSessionsResponse();
      if (getSessionsResponseTest == false) {
        history.push("/signout");
      } else {
        try {
          var accessTokenObj = await oktaAuth.tokenManager.get("accessToken");
          let oktaUserInfoObj = await oktaAuth.getUser();
          if (resetPwd.hasTriggered || cookies.PWD_RESET == "YES") {
            resetPwd.hasTriggered = false;
            document.cookie = 'isFormSkipped=;expires=Thu, 01 Jan 2010 00:00:00 UTC; path=/;domain=cardinalhealth.com';
            setCookie("PWD_RESET", "NO", {
              path: "/",
              domain: envConfig.COOKIE_DOMAIN,
              encode: (v) => v,
            });
            const resetPwdConfEmailResponse =
              await resetPasswordConfirmationEmail(oktaUserInfoObj,releaseFeatureFlag);
            }
            else if (setPwd.hasTriggered || cookies.PWD_SET == "YES") {
              setPwd.hasTriggered = false;
              setCookie("PWD_SET", "NO", {
                path: "/",
                domain: envConfig.COOKIE_DOMAIN,
                encode: (v) => v,
              });
              const setPwdConfEmailResponse =
                await setPasswordConfirmationEmail(oktaUserInfoObj,releaseFeatureFlag);
          }
          else {
            console.log(
              "Reset Pwd Object not updated to true/No PWD_RESET cookie found"
            );
          }
          //Coexistence
          /* var user = accessTokenObj.claims.sub;
          if (user.includes("@" + DOMAIN)) {
            user = user.split("@")[0];
          } */
          const factors = await getFactorsEnrolled();
          if (factors && factors.data) {
            for (const factor of factors.data) {
              if (factor.factorType === "call" && factor.status === "ACTIVE") {
                callEnrolled = true;
              }
              if (factor.factorType === "sms" && factor.status === "ACTIVE") {
                smsEnrolled = true;
              }
              if (factor.factorType === "email" && factor.status === "ACTIVE") {
                emailEnrolled = true;
              }
            }
          }
          /* POC Changes for OE-3246 Publix work starts*/
          //Setting cookie only if it is an OE Acute user as Publix will be Acute users only
          try{
            const response = await featureflagService();
            const publixFlagValue = response?.pdlogin_publix?.okta_user_group;
            console.log("Inside publix code block::"+publixFlagValue);
          if(oktaUserInfoObj.groups.includes(envConfig.ACUTE_GRP) &&  publixFlagValue !== null && publixFlagValue == true){
            if(oktaUserInfoObj.Building !== undefined && oktaUserInfoObj.Building !== null){
              console.log('Building for user '+oktaUserInfoObj.userid+' is :'+oktaUserInfoObj.Building);
              setCookie('PUBLIX_BUILDING', oktaUserInfoObj.Building , { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });
            }
            if(oktaUserInfoObj.PublixRole !== undefined && oktaUserInfoObj.PublixRole !== null){
              console.log('Role for user '+oktaUserInfoObj.userid+' is :'+oktaUserInfoObj.PublixRole);
              setCookie('PUBLIX_ROLE', oktaUserInfoObj.PublixRole , { path: '/', domain: envConfig.COOKIE_DOMAIN, encode: v => v });
            }
          }
        }catch (error) {
          console.error('Failed to fetch feature flag:', error);
        }
          /* POC Changes for OE-3246 Publix work ends*/
          let coexistResponse;
          userEnrollmentException = oktaUserInfoObj.groups.includes(
            envConfig.EXCEPTION_GRP
          );
          if (location.search.includes("skipEnrollment")) {
            skippedEnrollment = true;
            document.cookie = `isSkipEnrollment=true;path=/;domain=cardinalhealth.com`;
            console.log("Redirect happened after SkipEnrollment");
          } else {
            console.log("Redirect happened NOT SkipEnrollment");
          }
          console.log(
            `EmailEnrolled ${emailEnrolled}, CallEnrolled ${callEnrolled} , SmsEnrolled ${smsEnrolled} and UserEnrollmentExceptionGroup ${userEnrollmentException}`
          );
          if (
            emailEnrolled &&
            (callEnrolled || smsEnrolled) &&
            oktaUserInfoObj.UIBypassCounter !== "0"
          ) {
            const resetUIBypassCounterResponse = await incrementUIBypassCounter(
              "0"
            );
          
          }

          if (
            skippedEnrollment ||
            userEnrollmentException ||
            (emailEnrolled && (callEnrolled || smsEnrolled))
          ) {
            console.log(
              "Calling Coexistence, if its SkipEnrollmentClicked Or userEnrollException Or (EmailEnrolled, one of Call or SMS enrolled)"
            );
          
            if(oktaUserInfoObj.groups.indexOf('O-VantusHQ') == -1){
              coexistResponse = await coexistenceRequest(
                oktaUserInfoObj.userid,
                accessTokenObj.accessToken
              );
           
              if (
                coexistResponse &&
                coexistResponse.data &&
                coexistResponse.data.resultCode === "LOGIN_SUCCESS"
              ) {
                setCookie("SMSESSION", coexistResponse.data.sessionToken, {
                  path: "/",
                  domain: envConfig.COOKIE_DOMAIN,
                  encode: (v) => v,
                });
                setCoexistence("success");
              } else {
                setCoexistence("failure");
                //setCoexistence("success"); // local testing..
              }
            }
           
          }
          setUserInfo(oktaUserInfoObj);
        } catch (e) {
          setCoexistence("failure");
          console.error(e);
        }
      }
    }
  }
  }, [authState, oktaAuth, location,releaseFeatureFlag]); // Update if authState changes


  let incomingTargetUri = windowHrefUrl.includes("fromURI=") ? windowHrefUrl.substring(windowHrefUrl.indexOf("fromURI=") + 8) : cookies.TARGETURI;
  let target =null;

  
  const VerifyCheck = async () => {
    console.log("inside verify check",skippedEnrollment,userEnrollmentException,emailEnrolled,callEnrolled,smsEnrolled)
    // Email Not verified and Email Enrollment also not done, so navigate to email enrollment
     if(skippedEnrollment || userEnrollmentException || (emailEnrolled && (callEnrolled || smsEnrolled))) {
       routeToOELandingPage();
     } else if(!emailEnrolled) {
       history.push('/email-enrollment');
     } else if (emailEnrolled && (!callEnrolled  && !smsEnrolled)) {
       history.push('/phone-enrollment');
     }
     return <div></div>;
   };
   
 
  //  let incomingTargetUri = windowHrefUrl.includes("fromURI=") ? windowHrefUrl.substring(windowHrefUrl.indexOf("fromURI=") + 8) : cookies.TARGETURI;
  //  let target =null;

 
   const routeToOELandingPage = () => {

    if((userInfo.groups.indexOf('O-VantusHQ') !== -1) || (incomingTargetUri.includes("jarvis")) || (incomingTargetUri.includes("vantus"))){
        target = envConfig.NEW_ADDT_RI_CORE_COMMERCE_URL
        
    } else if( (incomingTargetUri.includes("bridgelogindev")) || (incomingTargetUri.includes("dashboard-orderexpress"))){
        target = incomingTargetUri;
    }
    else {
        target = findTargetUrl(userInfo.groups, incomingTargetUri);
    }
   
    if(target === 'Unavailable') {
      history.push('/signout')
    } else {
      window.location.assign(target);
    }
  }


  
  if (!authState) return null;
  return authState.isAuthenticated ? (
  
    <div>
      {/*coexistence === "failure" && <Redirect to={{ pathname: "/signout" }} /> */}
      { coexistence === "failure" ? <Signout/> : <></>}
      { (!userInfo || !coexistence) && <div></div> }
      {userInfo ? <VerifyCheck/> : <></>}
    </div>
  ) : (<Redirect to={{ pathname: "/signin" }} />);
};



export default Home;
